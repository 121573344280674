html, body{
  background-color: #f5f5f5;
}

.container {
  margin: 0 auto;
  /*max-width: 1280px;*/
  width: 100%;
}

@media only screen and (min-width: 601px) {
  .container {
    width: 90%;
  }
}

@media only screen and (min-width: 993px) {
  .container {
    width: 90%;
  }
}


/*# sourceMappingURL=data:application/json;charset=utf8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImluZGV4LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLDBCQUEwQjtDQUMzQjs7QUFFRDtFQUNFLGVBQWU7RUFDZixzQkFBc0I7RUFDdEIsWUFBWTtDQUNiOztBQUVEO0VBQ0U7SUFDRSxXQUFXO0dBQ1o7Q0FDRjs7QUFFRDtFQUNFO0lBQ0UsV0FBVztHQUNaO0NBQ0YiLCJmaWxlIjoiaW5kZXguY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaHRtbCwgYm9keXtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2Y1ZjVmNTtcbn1cblxuLmNvbnRhaW5lciB7XG4gIG1hcmdpbjogMCBhdXRvO1xuICAvKm1heC13aWR0aDogMTI4MHB4OyovXG4gIHdpZHRoOiAxMDAlO1xufVxuXG5AbWVkaWEgb25seSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDYwMXB4KSB7XG4gIC5jb250YWluZXIge1xuICAgIHdpZHRoOiA5MCU7XG4gIH1cbn1cblxuQG1lZGlhIG9ubHkgc2NyZWVuIGFuZCAobWluLXdpZHRoOiA5OTNweCkge1xuICAuY29udGFpbmVyIHtcbiAgICB3aWR0aDogOTAlO1xuICB9XG59XG5cbiJdfQ== */

#contadores-geral md-card {
  font-size: 18px;
}

#contadores-geral md-card-content > div {
  min-height: 120px;
}

#contadores-geral .cont {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

#barra, .pizza {
  width: 100%;
  height: 400px;
}

md-select-header > div{
  padding: 10.66667px;
  padding-bottom: 0px;
  border-bottom: 1px solid #CCC;
  background-color: #eceff1;
}

/*# sourceMappingURL=data:application/json;charset=utf8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC9yZXF1ZXJpbWVudG9zL3JlcXVlcmltZW50b3MuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsZ0JBQWdCO0NBQ2pCOztBQUVEO0VBQ0Usa0JBQWtCO0NBQ25COztBQUVEO0VBQ0UsbUJBQW1CO0VBQ25CLGdCQUFnQjtFQUNoQixrQkFBa0I7Q0FDbkI7O0FBRUQ7RUFDRSxZQUFZO0VBQ1osY0FBYztDQUNmOztBQUVEO0VBQ0Usb0JBQW9CO0VBQ3BCLG9CQUFvQjtFQUNwQiw4QkFBOEI7RUFDOUIsMEJBQTBCO0NBQzNCIiwiZmlsZSI6ImFwcC9yZXF1ZXJpbWVudG9zL3JlcXVlcmltZW50b3MuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiI2NvbnRhZG9yZXMtZ2VyYWwgbWQtY2FyZCB7XG4gIGZvbnQtc2l6ZTogMThweDtcbn1cblxuI2NvbnRhZG9yZXMtZ2VyYWwgbWQtY2FyZC1jb250ZW50ID4gZGl2IHtcbiAgbWluLWhlaWdodDogMTIwcHg7XG59XG5cbiNjb250YWRvcmVzLWdlcmFsIC5jb250IHtcbiAgdGV4dC1hbGlnbjogY2VudGVyO1xuICBmb250LXNpemU6IDMwcHg7XG4gIGZvbnQtd2VpZ2h0OiBib2xkO1xufVxuXG4jYmFycmEsIC5waXp6YSB7XG4gIHdpZHRoOiAxMDAlO1xuICBoZWlnaHQ6IDQwMHB4O1xufVxuXG5tZC1zZWxlY3QtaGVhZGVyID4gZGl2e1xuICBwYWRkaW5nOiAxMC42NjY2N3B4O1xuICBwYWRkaW5nLWJvdHRvbTogMHB4O1xuICBib3JkZXItYm90dG9tOiAxcHggc29saWQgI0NDQztcbiAgYmFja2dyb3VuZC1jb2xvcjogI2VjZWZmMTtcbn1cbiJdfQ== */
