html, body{
  background-color: #f5f5f5;
}

.container {
  margin: 0 auto;
  /*max-width: 1280px;*/
  width: 100%;
}

@media only screen and (min-width: 601px) {
  .container {
    width: 90%;
  }
}

@media only screen and (min-width: 993px) {
  .container {
    width: 90%;
  }
}

